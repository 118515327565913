import { isDefined } from "@clipboard-health/util-ts";
import { IonPage } from "@ionic/react";
import { TabRouterPath } from "@src/app/routing/constant/tabRoute";
import { useGetAttendanceScoreProfile } from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreProfile";
import { useGetFacilitiesExclusions } from "@src/appV2/Facilities/api/useGetFacilitiesExclusions";
import { AppBarHeader, PageWithHeader } from "@src/appV2/lib";
import { STREAK_TRACKER_PATH } from "@src/appV2/Streaks";
import {
  useStreaksEnabledForHcp,
  useStreaksEnabledGlobally,
} from "@src/appV2/Streaks/api/featureFlagHooks";
import { useGetAgentActiveStreaks } from "@src/appV2/Streaks/api/useGetAgentActiveStreaks";
import {
  filterStreaksByDateRange,
  getCurrentActiveStreaks,
  getPayBoostActiveStreaks,
} from "@src/appV2/Streaks/utils";
import { OnboardingIncentiveBanner } from "@src/appV2/WorkerOnboarding/OnboardingIncentiveBanner";
import { MioStages } from "@src/appV2/WorkerOnboarding/types";
import { useDefinedAgent } from "@store/helperHooks";
import { useQueryClient } from "@tanstack/react-query";
import { startOfWeek, subWeeks } from "date-fns";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";

import { getWorkplaceCountPath } from "./api/getPreferredWorkplaceCount";
import { RatingCardProps } from "./cards/RatingsCard";
import { RatingCardContainer } from "./cards/RatingsCardContainer";
import { pluralizeFacility } from "./lang";
import { logViewDetailsClicked } from "./logs";
import { getScoreSeverity } from "./utils";

export function RatingsPage() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { userId = "" } = useDefinedAgent();

  const {
    isLoading: isAttendanceScoreLoading,
    data: attendanceScoreData,
    refetch: refetchAttendanceScore,
  } = useGetAttendanceScoreProfile(userId);

  const isStreaksEnabledGlobally = useStreaksEnabledGlobally();
  const isStreaksOnForHcp = useStreaksEnabledForHcp();

  const {
    isLoading: isStreaksLoading,
    data: streaksResponse,
    refetch: refetchStreaks,
  } = useGetAgentActiveStreaks(
    { agentId: userId },
    { enabled: !isEmpty(userId) && isStreaksEnabledGlobally }
  );

  const handleAttendanceScoreClicked = () => {
    logViewDetailsClicked(userId, "attendance score");
    history.push(TabRouterPath.RATINGS_ATTENDANCE_SCORE);
  };

  const payBoostActiveStreaks = getPayBoostActiveStreaks(streaksResponse?.data);
  const exclusionsQueries = useGetFacilitiesExclusions({
    facilityIds: payBoostActiveStreaks.map((streak) => streak.attributes.facilityId),
    agentId: userId,
  });

  const excludedFacilities = new Set(
    exclusionsQueries
      .flatMap((queryResult) => queryResult.data?.data)
      .map((exclusion) => exclusion?.facility.userId)
      .filter((id) => isDefined(id))
  );

  const streaksAtValidFacilities = payBoostActiveStreaks.filter((streak) => {
    return !excludedFacilities.has(streak.attributes.facilityId);
  });

  const currentPayBoostActiveStreaks = getCurrentActiveStreaks(streaksAtValidFacilities);

  const hasPayBoostActiveStreakInTheLast2Weeks =
    filterStreaksByDateRange({
      streaks: streaksAtValidFacilities,
      from: subWeeks(startOfWeek(new Date()), 2),
    }).length > 0;

  const isLoading = isAttendanceScoreLoading || (isStreaksEnabledGlobally && isStreaksLoading);

  const cards: RatingCardProps[] = [];
  if (attendanceScoreData) {
    cards.push({
      title: "Attendance Score",
      description: `Your account’s attendance score is ${attendanceScoreData.score}. Learn more about how Attendance Score works.`,
      buttonText: "See details",
      buttonOnClick: handleAttendanceScoreClicked,
      scoreValue: attendanceScoreData.score,
      scoreColor: getScoreSeverity(attendanceScoreData.score),
      testId: "attendance-score",
    });
  }

  if (isStreaksEnabledGlobally && (isStreaksOnForHcp || hasPayBoostActiveStreakInTheLast2Weeks)) {
    cards.push({
      title: "Streaks (Beta)",
      description: `You have active streaks at ${pluralizeFacility(
        currentPayBoostActiveStreaks.length
      )}. Earn bonuses at facilities where you have a streak. Learn more about how Streaks works.`,
      buttonText: "See details",
      buttonOnClick: () => {
        logViewDetailsClicked(userId, "streaks");
        history.push(STREAK_TRACKER_PATH);
      },
      scoreValue: currentPayBoostActiveStreaks.length,
      scoreColor: "streak",
      testId: "streaks",
    });
  }

  function refetchPreferredWorkplace() {
    const path = getWorkplaceCountPath(userId);
    queryClient.invalidateQueries([path]);
  }

  function refetchWorkerRankings() {
    queryClient.invalidateQueries({
      predicate: (query) => {
        return query.queryKey?.[0]?.toString().includes("/rankings") ?? false;
      },
    });
  }

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <>
            <OnboardingIncentiveBanner workerId={userId} stage={MioStages.FINAL} />
            <AppBarHeader title="Account Status" />
          </>
        }
        containerVariant="without-margin"
        containerMaxWidth="lg"
        onRefresh={() => {
          return Promise.all([
            refetchAttendanceScore(),
            refetchWorkerRankings(),
            refetchPreferredWorkplace(),
            isStreaksEnabledGlobally ? refetchStreaks() : Promise.resolve(),
          ]);
        }}
      >
        <RatingCardContainer cards={cards} isLoading={isLoading} />
      </PageWithHeader>
    </IonPage>
  );
}
