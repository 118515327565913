import { logEvent } from "@src/appV2/lib/analytics";
import { USER_EVENTS } from "@src/constants";

export function logViewDetailsClicked(
  workerId: string,
  section: "attendance score" | "priority access" | "streaks" | "a-team"
) {
  logEvent(USER_EVENTS.RATINGS_PAGE_VIEW_DETAILS_CLICKED, {
    workerId,
    section,
  });
}

export function logViewFacilitiesClicked(
  workerId: string,
  numberOfFacilities: number,
  section: "favorited" | "high attendance",
  action: "listCollapsed" | "listExpanded"
) {
  logEvent(USER_EVENTS.PRIORITY_ACCESS_PAGE_VIEW_FACILITIES_CLICKED, {
    workerId,
    numberOfFacilities,
    section,
    action,
  });
}

export function logArticleClicked(workerId: string) {
  logEvent(USER_EVENTS.PRIORITY_ACCESS_PAGE_ARTICLE_CLICKED, {
    workerId,
  });
}
