import { isNullOrUndefined } from "@clipboard-health/util-ts";
import { TabRouterPath } from "@src/app/routing/constant/tabRoute";
import { useDefinedAgent } from "@src/app/store/helperHooks";
import { ATeamHcpStatus } from "@src/appV2/Rankings/types";
import { useGetATeamWorkplaces } from "@src/appV2/Rankings/useGetATeamWorkplaces";
import { useHistory } from "react-router-dom";

import { RatingCard, RatingCardProps } from "./RatingsCard";
import { usePreferredWorkplaceCount } from "../api/usePreferredWorkplaceCount";
import { CardLoader } from "../CardLoader";
import { pluralizeFacility, pluralizeFacilityWithThe } from "../lang";
import { logViewDetailsClicked } from "../logs";

export function PriorityAccessCard() {
  const history = useHistory();
  const { userId = "" } = useDefinedAgent();
  const { isLoading: isPreferredWorkplaceLoading, data: preferredWorkplaceData } =
    usePreferredWorkplaceCount(userId, {
      meta: {
        userErrorMessage: "Something went wrong while loading your priority access facilities.",
      },
    });

  const { aTeamStatus, aTeamWorkplacesCount } = useGetATeamWorkplaces();

  const handlePriorityAccessClicked = () => {
    logViewDetailsClicked(userId, "priority access");
    history.push(TabRouterPath.RATINGS_PRIORITY_ACCESS);
  };

  const handleAteamClicked = () => {
    logViewDetailsClicked(userId, "a-team");
    history.push(TabRouterPath.RATINGS_A_TEAM);
  };

  const numberOfPriorityAccesses = preferredWorkplaceData?.workplaceCountByReason
    ? preferredWorkplaceData.workplaceCountByReason.RATING +
      preferredWorkplaceData.workplaceCountByReason.INTERNAL_CRITERIA +
      preferredWorkplaceData.workplaceCountByReason.FAVORITE +
      aTeamWorkplacesCount
    : undefined;

  if (isPreferredWorkplaceLoading || isNullOrUndefined(numberOfPriorityAccesses)) {
    return <CardLoader />;
  }

  const priorityAccessCardProps: RatingCardProps = {
    title: "My Priority Access",
    description: `You have Priority Access at ${pluralizeFacility(numberOfPriorityAccesses)}. ${
      numberOfPriorityAccesses === 0
        ? "Learn more about how Priority Access works."
        : `View ${pluralizeFacilityWithThe(
            numberOfPriorityAccesses
          )} and learn more about how Priority Access works.`
    }`,
    buttonText: "See details",
    buttonOnClick: handlePriorityAccessClicked,
    scoreValue: numberOfPriorityAccesses,
    scoreColor: "shift-priority",
    testId: "priority-access",
  };
  const aTeamDescription =
    aTeamWorkplacesCount > 0
      ? `You've been added to the A-Team of ${pluralizeFacility(
          aTeamWorkplacesCount
        )} because of your excellent performance. This gives you exclusive early access to their shifts.`
      : `No Facilities have added you to their A-Team. Being a part of a facility's A-Team gives you exclusive early access to shifts.`;

  const aTeamCardProps: RatingCardProps = {
    title: "A-Team",
    description: aTeamDescription,
    buttonText: "See details",
    buttonOnClick: handleAteamClicked,
    scoreValue: aTeamWorkplacesCount,
    scoreColor: "shift-priority",
    testId: "a-team",
  };

  const cardProps =
    aTeamStatus === ATeamHcpStatus.A_TEAM ? aTeamCardProps : priorityAccessCardProps;

  return <RatingCard {...cardProps} />;
}
